// Run on document ready
$(function(){
    
    
    /* Send CSRF token with ajax requests */
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="_token"]').attr('content')
        }
    });
	
	
	/* Fastclick */
	FastClick.attach(document.body);
	
	
	/* Mobile Nav */
	$('#header .nav-icon').click(function(e){
		if( $('#header .lower nav').hasClass('opened') ){
			$('#header .lower nav').removeClass('opened');
		} else {
			$('#header .lower nav').addClass('opened');
		}
	});


	/* Date Pickers */
	$('input.datepicker').datepicker({
		dateFormat: 'yy-mm-dd'
	});


	/*
	// Slideshow
	$('#slideshow').flexslider({
		controlNav		: false,
		directionNav	: false,
		animation			: 'slide',
		slideshowSpeed: 3000
	});
	*/
	
	
	/* Admin: WYSIWYG (tinymce) */
	$('.wysiwyg').tinymce({
		script_url : '/assets/js/tinymce/tinymce.min.js',
			
		plugins: [
            "advlist autolink link image lists charmap print preview hr anchor pagebreak",
            "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
            "table contextmenu textcolor paste textcolor"
	    ],

	    toolbar1: "bold italic | alignleft aligncenter alignright alignjustify | fontsizeselect styleselect | cut copy paste | table | hr removeformat | subscript superscript charmap nonbreaking | print",
	    toolbar2: "searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink anchor image media | inserttime preview | code visualchars visualblocks",

	    menubar: false, 
	    toolbar_items_size: 'small',
			
			relative_urls: false,
			remove_script_host: true,
						
			style_formats : [
	    	{title: 'Headline 1', block: 'h1'},
	    	{title: 'Headline 2', block: 'h2'},
	    	{title: 'Headline 3', block: 'h3'},
	    	{title: 'Blue', inline: 'span', classes: 'clr-blue'},
	    	{title: 'Tan', inline: 'span', classes: 'clr-tan'},
	    	{title: 'Red', inline: 'span', classes: 'clr-red'},
	    	{title: 'Grey Box', block: 'div', classes: 'callout grey'}
	    ],
	    
	    content_css: "/assets/css/wysiwyg.css"
	});
	
});



/**
 * Confirm deletion and delete via ajax
 *
 * @param string path
 * @param string label
 * @param object element
 */
function confirmDeletion(path, label, element){
    if(confirm('Are you sure you want to delete this ' + label + '?')) {
        $.ajax({
            type: "post",
            data: { _method: "DELETE" },
            url: path,
            success: function(result){
                if(result == 0){
                    alert('The ' + label + ' could not be deleted.');
                } else {
                
                    // Remove a dom element?
                    if(element != undefined){
                        element.fadeOut(300, function(){
                            element.remove();
                        });
                    }
                }
            }
        });
    }
}
